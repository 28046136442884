var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-1",attrs:{"id":"steps"}},[_c('ul',[_c('li',{staticClass:"step"},[_c('div',{class:_vm.currentPosition >= 1 ? 'step-number checked' : 'step-number'},[(_vm.currentPosition < 1)?_c('i',[_vm._v("1")]):_vm._e()]),_vm._v(" "),_c('div',{class:_vm.currentPosition >= 1 ? 'step-line' : 'step-line step-line-inactive'})]),_vm._v(" "),_c('li',{staticClass:"step"},[_c('div',{class:_vm.currentPosition < 1
          ? 'step-number step-number-inactive'
          : _vm.currentPosition === 1
          ? 'step-number'
          : 'step-number checked'},[(_vm.currentPosition < 2)?_c('i',[_vm._v("2")]):_vm._e()]),_vm._v(" "),_c('div',{class:_vm.currentPosition < 2 ? 'step-line step-line-inactive' : 'step-line'})]),_vm._v(" "),_c('li',{staticClass:"step"},[_c('div',{class:_vm.currentPosition < 2
          ? 'step-number step-number-inactive'
          : _vm.currentPosition === 2
          ? 'step-number'
          : 'step-number checked'},[(_vm.currentPosition < 3)?_c('i',[_vm._v("3")]):_vm._e()])])])]),_vm._v(" "),_c('p-form',{staticClass:"column is-7",attrs:{"handler":_vm.emailRegistration,"validator":_vm.emailValidator},scopedSlots:_vm._u([{key:"fields",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"step-action"},[_c('button',{directives:[{name:"t",rawName:"v-t",value:("competition.buttons.install"),expression:"`competition.buttons.install`"}],class:_vm.currentPosition > 0 ? 'button special-style special-button' : 'button special-button is-primary',attrs:{"disabled":_vm.currentPosition >= 1},on:{"click":function($event){return _vm.redirectToStore()}}}),_vm._v(" "),_c('div',{directives:[{name:"t",rawName:"v-t",value:("competition.is-free"),expression:"`competition.is-free`"}],staticClass:"action-description"})]),_vm._v(" "),_c('div',{staticClass:"step-action"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.Email),expression:"data.Email"}],staticClass:"special-input step-line-inactive",attrs:{"type":"email","disabled":_vm.currentPosition < 1,"placeholder":_vm.$t('competition.enter-email')},domProps:{"value":(data.Email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(data, "Email", $event.target.value)},_vm.handleEmailInput]}}),_vm._v(" "),_c('i18n',{attrs:{"path":"competition.agree-to-competition","for":"terms-link"},scopedSlots:_vm._u([{key:"terms-link",fn:function(){return [_c('a',{directives:[{name:"t",rawName:"v-t",value:("links.terms-and-conditions"),expression:"`links.terms-and-conditions`"}],attrs:{"target":"_blank","rel":"noopener noreferrer","href":"/terms-and-conditions"}})]},proxy:true}],null,true)})],1)]}},{key:"actions",fn:function(ref){
          var stage = ref.stage;
          var stages = ref.stages;
return [_c('p-field',{staticClass:"step-action"},[_c('p-button',{directives:[{name:"t",rawName:"v-t",value:("competition.buttons.enter"),expression:"`competition.buttons.enter`"}],class:_vm.currentPosition === 2 ? 'button special-button is-primary' : 'button special-style special-button',attrs:{"loading":stage === stages.SUBMITTED,"disabled":_vm.currentPosition !== 2}}),_vm._v(" "),_c('i18n',{staticClass:"action-description",attrs:{"path":"competition.visit-privacy-policy","for":"privacy-link"},scopedSlots:_vm._u([{key:"privacy-link",fn:function(){return [_c('a',{directives:[{name:"t",rawName:"v-t",value:("links.privacy-policy"),expression:"`links.privacy-policy`"}],attrs:{"href":"/privacy-policy","target":"_blank","rel":"noopener noreferrer"}})]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }