<template>
  <main-layout>

    <section class="section special-bg-color">
      <div class="container has-text-centered has-text-white">
        <h1 v-t="`competition.win-1000`"></h1>
        <span v-t="`competition.complete-all`"></span>
      </div>

      <div class="columns">
        <div class="column is-half">
          <img v-lazy="`$/images/gener8_website.png`" :alt="$t('Gener8 website from laptop')">
        </div>
        <div class="column is-5">
          <step-scale></step-scale>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container details has-text-centered">
        <p>
          <span v-t="`competition.gener8`"></span>
          <strong v-t="`competition.pouch-helps`"></strong>
        </p>

        <p v-t="`competition.best-couple`"></p>
        <p v-t="`competition.average-saving`"></p>

        <p>
          <span v-t="`competition.celebrate`"></span>
          <strong v-t="`competition.money-rain`"></strong>
          <span v-t="`competition.how-to-compete`"></span>
        </p>

        <div v-t="`competition.wish-for-success`" class="is-uppercase special-style-color"></div>
        <p v-t="`competition.pouch-J-team`"></p>
      </div>
    </section>
    <section class="section-video">
      <div class="video-container">
        <iframe
          width="800"
          height="600"
          src="https://www.youtube.com/embed/UChJFgReb0Y"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>

    <section class="section-info has-text-centered">
      <p v-t="`links.terms-and-conditions`"></p>
      <p v-t="`competition.promoter`"></p>
      <p v-t="`competition.postcode`"></p>
      <a v-t="`competition.read-more`" href="/articles/pouch-and-gener8"></a>
    </section>
  </main-layout>
</template>

<script>
import StepScale from './components/StepScale'

export default {
  components: { StepScale }
}
</script>

<style lang="sass" scoped>

.section
  height: auto
  font-size: 1.5rem

.special-bg-color
  background-color: #71ACB3

.special-style-color
  color: #71ACB3
  font-weight: 800

.container
  margin-bottom: 5%

  h1
    font-size: 2.4rem
    font-weight: 700
    font-style: bold
.details
  margin-top: 3%
  width: 80%
  font-size: 1.3rem
  p
    margin-bottom: 2rem
  strong
    font-weight: 800

.columns
  justify-content: center
  img
    margin-top: 5%

.section-video
  width: 100%
  min-height: 600px

  .video-container
    display: flex
    justify-content: center
    min-width: 1200px
    background-image: url(/static/images/gener8-video-bg.svg)
    background-repeat: no-repeat
    background-image: center
    background-size: 100%

    video
      height: 600px
      display: block
      outline: none
      margin-left: auto
      margin-right: auto

.section-info
  margin-top: 4rem
  margin-bottom: 2rem

  p
    opacity: 0.7
  a
    text-decoration: underline
</style>
