import Validator from './Validator'

export default class extends Validator {
  InvalidParameterException () {
    return {
      Email: [
        {
          validator: value => !!value,
          message: value => 'errors.email-form.email-empty'
        },
        {
          validator: value => !value || value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
          message: value => 'errors.email-form.invalid-email'
        }
      ],

      Checkbox: [
        {
          validator: value => !!value,
          message: value => 'errors.email-form.checkbox-error'
        }
      ]
    }
  }
}
