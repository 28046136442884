<template>
  <section class="columns is-mobile">
    <div id="steps" class="column is-1">
      <ul>
        <li class="step">
          <div :class="currentPosition >= 1 ? 'step-number checked' : 'step-number'">
            <i v-if="currentPosition < 1">1</i>
          </div>
          <div :class="currentPosition >= 1 ? 'step-line' : 'step-line step-line-inactive'"></div>
        </li>
        <li class="step">
          <div :class="currentPosition < 1
            ? 'step-number step-number-inactive'
            : currentPosition === 1
            ? 'step-number'
            : 'step-number checked'"
          >
            <i v-if="currentPosition < 2">2</i>
          </div>
          <div :class="currentPosition < 2 ? 'step-line step-line-inactive' : 'step-line'"></div>
        </li>
        <li class="step">
          <div :class="currentPosition < 2
            ? 'step-number step-number-inactive'
            : currentPosition === 2
            ? 'step-number'
            : 'step-number checked'"
          >
            <i v-if="currentPosition < 3">3</i>
          </div>
        </li>
      </ul>
    </div>

    <p-form :handler="emailRegistration" :validator="emailValidator" class="column is-7">
      <template slot="fields" slot-scope="{ data }">
        <div class="step-action">
          <button
            v-t="`competition.buttons.install`"
            :class=" currentPosition > 0 ? 'button special-style special-button' : 'button special-button is-primary'"
            @click="redirectToStore()"
            :disabled="currentPosition >= 1"
          ></button>
          <div class="action-description" v-t="`competition.is-free`"></div>
        </div>

        <div class="step-action">
            <input
              type="email"
              class="special-input step-line-inactive"
              @input="handleEmailInput"
              v-model="data.Email"
              :disabled="currentPosition < 1"
              :placeholder="$t('competition.enter-email')"
            />
          <i18n path="competition.agree-to-competition" for="terms-link">
            <template v-slot:terms-link>
              <a target="_blank"
                rel="noopener noreferrer"
                href="/terms-and-conditions" v-t="`links.terms-and-conditions`"
              ></a>
            </template>
          </i18n>
        </div>
      </template>

      <template slot="actions" slot-scope="{ stage, stages }">
        <p-field class="step-action">
          <p-button
            v-t="`competition.buttons.enter`"
            :class="currentPosition === 2 ? 'button special-button is-primary' : 'button special-style special-button'"
            :loading="stage === stages.SUBMITTED"
            :disabled="currentPosition !== 2"
          ></p-button>
          <i18n path="competition.visit-privacy-policy"
            for="privacy-link"
            class="action-description"
          >
            <template v-slot:privacy-link class="adjust-height-line">
              <a href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                v-t="`links.privacy-policy`"
              ></a>
            </template>
          </i18n>
        </p-field>
      </template>
    </p-form>
  </section>
</template>

<script>
import Uid from '@/core/services/Uid'
import Utm from '@/core/services/Utm'
import EmailValidator from '@/core/validators/EmailValidator'

export default {
  data: function () {
    return {
      currentPosition: 0,
      emailValidator: new EmailValidator()
    }
  },

  computed: {
    redirect () {
      return '/deals'
    }
  },

  methods: {
    /**
     * Set the current position.
     */
    setPosition (position) {
      return this.currentPosition = position
    },

    /**
     * Handle email input
     */
    handleEmailInput (e) {
      const email = e.target.value

      if (email) {
        this.setPosition(2)
      } else {
        this.setPosition(1)
      }
    },

    /**
     * The email is checked and sent.
     */
    async emailRegistration (data) {
      if (!data.Email || !data.Email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
        return Promise.reject({ code: 'InvalidParameterException' })
      }

      const mailchimpTemplate = this.$mailchimp.getTemplate(data.Email, 'gener8_competition')
      return this.$store.dispatch('postDataToMailchimp', mailchimpTemplate)
        .then(() => {
          this.setPosition(3)
          this.$messages.success(this.$t('competition.thanks'), '', true)
          this.$router.push(this.redirect)
        })
        .catch(console.log)
    },

    /**
     * Redirect the installation to the right broswer store.
     */
    redirectToStore () {
      // Change position
      this.setPosition(1)

      // Fire an event.
      this.$dispatcher.fire('app.addToBrowserClicked', { route: this.$route })

      // Convert `$browser.name` to corresponding config webstore key
      const storeKey = name => name === 'edge-chromium' ? 'edge' : name

      const url = [
        Utm.addQuery.bind(Utm),
        Uid.addQuery.bind(Uid)
      ].reduce(
        (carry, pipe) => pipe(carry),
        new URL(this.$config.extension.installationLinks[storeKey(this.$browser.name)])
      ).toString()

      // Redirect to the store in a new Tab.
      if (this.$browser.name === 'chrome') {
        window.open(
          url,
          'Install Pouch'
        ).focus()
      } else {
        window.open(url, '_blank').focus()
      }

      // Bubble the click event up.
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass" scoped>
.columns
  justify-content: center
  .column
    min-width: 4rem

  #steps
    display: inline-block
    margin-top: 2.5rem
    .step-number
      padding-top: 6px
      font-size: 1.5rem
      text-align: center
      width: 3rem
      height: 3rem
      border-radius: 100%
      background-color: white
      margin-bottom: 1rem
      color: black
    .checked
      background-image: url(/static/images/check.svg)
      background-size: auto 60%
      background-repeat: no-repeat
      background-position: center
    .step-line
      background-color: white
      width: .2rem
      height: 4rem
      margin-left: 1.45rem
      margin-bottom: 1rem
    .step-number-inactive
      color: #47868E
      border: .1rem solid #47868E
      background-color: transparent
    .step-line-inactive
      background-color: #47868E

  .step-action
    background-color: #47868E
    color: white
    height: 8rem
    width: 20rem
    padding: 1rem
    margin-bottom: 1rem
    border-radius: .5rem
    &:hover
      transform: scale(1.03) !important
    .special-button
      width: 100%
      font-size: 1.3rem
      font-weight: 700
    .special-input
      outline: none
      padding: 13.5px 20px 13.5px
      border-radius: 5px
      border: 0px
      width: 100%
      font-size: 1.3rem
      font-weight: 700
      &:disabled
        cursor: not-allowed
    .special-style
      color: white
      border: 1px solid white
      background-color: transparent
    .special-style:hover
      color: #3C3C3C
      background-color: white
    .action-description
      margin-top: .5rem
      font-size: .7rem !important
      line-height: 1.3 !important
  span
    display: block
    margin-top: .5rem
    font-size: .7rem
    line-height: 1.3 !important
    a
      color: black
      text-decoration: underline
      line-height: .1 !important
</style>
